import React from 'react';
import PropTypes from 'prop-types';

const MaterijalPageTemplate = ({ title, logo }) => (
  <div>
    <section className='hero is-primary is-bold is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-one-fifth is-1'>
              <img alt='' src={logo} />
            </div>
            <div className='column is-10'>
              <div className='section'>
                <h1 className='title'>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section section--gradient'>
      <div className='container'>
        <div className='content'>
          {' '}
          <h3 className='has-text-weight-semibold is-size-2'>Materijali</h3>
          <p className='has-text-justified'>
            <a href='https://drive.google.com/file/d/1RQE67W4ZFSK8_N7xj9CagNyPQrUchHU4/view?pli=1'>
              Roblox
            </a>
          </p>
          <p className='has-text-justified'>            
            <a href='https://docs.google.com/document/d/1ku9YrACoT9xZ8ZzdHrwgnbP-UvHTTHdA/edit?usp=sharing&ouid=117020438719169027364&rtpof=true&sd=true'>
              Minecraft
            </a>
          </p>
        </div>
      </div>
    </section>
  </div>
);

MaterijalPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  //   content: PropTypes.string,
  //   contentComponent: PropTypes.func,
  logo: PropTypes.string,
};
export default MaterijalPageTemplate;

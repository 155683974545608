import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import MaterijalPageTemplate from '../components/MaterijalPageTemplate';

const MaterijalPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <MaterijalPageTemplate
        title={post.frontmatter.title}
        // meta_title={post.frontmatter.meta_title}
        // meta_description={post.frontmatter.meta_description}
        logo={post.frontmatter.logo}
      />
    </Layout>
  );
};
MaterijalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default MaterijalPage;

export const materijalPageQuery = graphql`
  query MatrijalPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        logo
        meta_title
        meta_description
      }
    }
  }
`;
